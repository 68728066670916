.container-validation .row .block-1{
    padding: 7em 6em;
}

.container-validation .row .block-1 .title{
    font-size: 47px;
}

.container-validation .row .block-1 div.text{
    font-size: 25px;
    height: 100%;
    text-align: justify;
    white-space: pre-line;
}

.container-validation .row .block-2{
    padding: 2.5em 6em 0;
}

.container-validation .row .block-2 input{
    margin: 30px 0 20px;
    padding: 8px 13px;
    font-size: 22px;
    color: #2f6498;
    width: 100%;
    border: 3px solid #043463;
}

.container-validation .row .block-2 .title{
    font-size: 40px;
    text-align: center;
    margin: 25px 0;
}

.container-validation .row .block-2 div.text{
    font-size: 22px;
    text-align: justify;
    
}

.container-validation .row .block-2 .btn{
    width: 100%;
    padding: 6px 10px;
    font-size: 22px;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 0;
}

.container-validation .row .block-2 .validar{
    background: #178f15;
    box-shadow: 0px 4px 3px #c5c5c5;
}

.container-validation .row .block-2 .acessar-sistema{
    margin: 60px 0 140px;
    background: #0D7CCE;
    box-shadow: 0px 4px 3px #b5bcc1;
}

.container-validation .row .block-2 .tutorial{
    background: #c3bfbf;
    box-shadow: 0px 4px 3px #b5bcc1;
    margin-bottom: 88px;
}

.container-validation .row .block-2 .btn:hover{
    opacity: 0.8;
}

.container-validation .hide-area{
    display: none;
}

.container-validation .show-area{
    display: block;
}