.header{
    background-color: #0D7CCE;
    color: white;
    font-size: 50px;
    padding-left: 44px;
    height: 115px;
    align-items: center;
    display: flex;
}


.header .search{
    border-radius: 18px;
    padding: 5px 15px 5px 20px;
    background: white;
    color: #043463;
    width: 40%;
    margin-left: auto;
}

.header button{
    border: 0;
    background: transparent;
    outline: 0;
    display: contents;
}

.header .search input{
    font-size: 19px;
    margin-right: 7px;
    border: 0;
    background: transparent;
    outline: 0;
    width: 100%;
}

.header .icon-contraste{
    color: white;
    font-size: 30px;
    margin: 0 1.6em;
}