.container-validation, 
.container-login{
    min-height: 100%;
}

.container-validation.grid-container, 
.container-login.grid-container {
    display: grid;
    grid-template-rows: 6fr 0.8fr 0.35fr;
}

.container-validation .row .block-1, 
.container-login .row .block-1{
    background-color: #0D7CCE;
    color: white;
}

.container-validation .row .block-2, 
.container-login .row .block-2{
    background-color: white;
}

.container-validation .block-3, 
.container-login .block-3{
    background-color: #0D7CCE;
}


.container-validation footer, 
.container-login footer{
    background-color: #c3bfbf;
    color: white;
    display: flex;
    align-items: center; 
    justify-content: center;
    font-size: 17px;
}

.container-validation .block-3,
.container-login .block-3{
    display: flex;
    align-items: center; 
}

.container-validation .block-3 .logo-ufma,
.container-login .block-3 .logo-ufma{
    width: 90px;
    margin-left: 5em;
}

.container-validation .block-3 .address,
.container-login .block-3 .address{
    font-size: 18px;
    color: white;
    padding: 0 3em;
    width: 24em;
    margin-left: auto;
}

.container-validation .row .block-2 .icon,
.container-login .row .block-2 .icon{
    font-size: 35px;
    text-align: right;
}
