.main-home {
    padding: 3em;
    padding-left: 0;
}

.main-home button {
    width: 100%;
    padding: 10px;
    margin: 0;
    min-height: 7em;
    /*min-height: 112px;
    min-width: 164px;*/
    background: #EAEAEA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    white-space: normal;
}

.main-home button:hover {
    background: #dfdfdf;
}

.main-home button i{
    font-size: 2.9vw;
    color: #0D7CCE;
}

.main-home button .text{
    font-size: 1.5vw;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: normal;
    line-height: 130%;
}

.main-home .row .column {
    padding: 0;
    padding-bottom: 3em;
}

.main-home .row .column {
    padding-left: 3em;
}