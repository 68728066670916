.box-document{
    text-align: center;
    background-color: var(--backgroundModal);
    border-radius: 4px;
    margin: 1rem 0;
    padding: 1rem 0;
}

.box-document .icon{
    font-size: 30px;
    display: flex;
    justify-content: center;
    
}

.box-document .icon span{
    display: block;
}

.box-document .message-validate{
    font-weight: 500;
    font-style: italic;
    font-size: 18px;
    margin: 1rem 0;
}

.box-document .download-document{
    color: var(--dark_gray);
}

.box-document .download-document .download{
    cursor: pointer !important;
}

.error-msg {
    font-size: 1.2rem;
    color: red;
    text-align: center;
}