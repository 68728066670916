.dropdown {
  position: relative;
  display: inline-block;
}

.btn-download {
  margin: 0 !important;
  padding: 0 !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--backgroundModal);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-align: center;
  left: 0;
  top: 12px;
  transform: translateX(-100%) translateY(-100%);
}

.dropdown-content .dropdown-item {
  padding: 12px 12px;
  text-decoration: none;
  display: block;
}

.dropdown-content .dropdown-item:hover {
  cursor: pointer;
  background-color: #f9f9f9;
}

.dropdown:hover .dropdown-content {
  display: block;
}