.container-menu{
    min-height: 100%;    
    font-family: "Noto Sans";
}

.container-menu .content{
    transition: all 0.3s;
    width: calc(100% - 365px);
    transition: all 0.3s;
}


/**/

.container-menu .wrapper {
    display: flex;
    width: 100%;
}

.container-menu .sidebar {
    width: 365px;
    min-height: calc(100vh - 115px - 41.5px - 86px);
    background: #EAEAEA;
    color: #fff;
    transition: all 0.3s;
}


.container-menu .wrapper .sidebar .dropdown-toggle::after {
    display: none;
}

.container-menu .wrapper .sidebar .navbar-nav .nav-item .div-btn{
    margin-top: 40px;
    border-left: 5px solid transparent;
}

.container-menu .wrapper .sidebar .navbar-nav .nav-item .div-btn.active{
    border-left: 5px solid #0D7CCE;
    background: white;
}


.container-menu .wrapper .sidebar .navbar-nav .nav-item .btn-item,
.container-menu .wrapper .sidebar .sidebar-header .btn-item{
    font-size: 27px;
    font-weight: bold;
    padding: 0;
    width: 100%;
    text-align: left;
    margin: 0;
    border: none;
    background: transparent;
    padding-left: 2.5em;
}

.container-menu .wrapper .sidebar .navbar-nav .nav-item .btn-item:hover{
    background-color: #f3f3f3;
}

.container-menu .wrapper .sidebar .navbar-nav .nav-item .div-btn.active .btn-item:hover{
    background-color: white;
}

.container-menu .wrapper .sidebar .sidebar-header .btn-item{
    width: 100%;
    text-align: center;
    padding: 23px 0;
    border-bottom: 1px solid#c3bfbf;
    margin: 0;
}

.container-menu .container{
    max-width: 100%;
    margin: 0;
}
