.navigation{
    text-decoration: none;
    color: #000000;
    font-size: 20px;
    padding-top: 20px;
    margin-top: 0;
}

.button-nav-group {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-nav-group input {
    font-size: 19px;
    width: 5em;
}

.button-nav-group button{
    color: #FFFFFF;
    background: #0D7CCE;
    font-size: 20px;
    font-weight: bolder;
    transition: filter 0.2s;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    width: 3.5rem;
}

.button-nav-group button:hover{
    filter: brightness(0.7);
    color: #FFFFFF;
}

.button-nav-group > *:not(:last-child) {
    margin-right: 0.25rem;
}

.hide-area{
    display: none;
}

.area-alert{
    display: inline-block;
    padding-top: 20px;
}

.line-amount > label {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.line-amount > label > input {
    width: 5rem;
    margin-left: 0.5rem;
}