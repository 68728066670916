.main-settings form{
    padding: 0;
}

.main-settings form label {
    font-style: normal;
    /*font-weight: 700;*/
    /*font-size: 1.2em;*/
    color: #000000;
}

.main-settings form input, .main-settings form textarea {
    border: 2px solid #6d96b3c9;
    box-sizing: border-box;
    border-radius: 4px;
}

.main-settings .modal-footer button{
    font-size: large;
    margin-top: 20px;
    width: 140px;
}

.main-settings .modal-footer{
    padding: 1rem;
}