/*.main-profile{
    min-height: 100%;    
    font-family: "Noto Sans";
}

.main-profile .content{
    min-height: calc(100vh - 115px - 41.5px - 86px);
    margin: 0;
}*/


.main-profile form{
    padding: 0;
}

.main-profile form label {
    font-style: normal;
    font-size: 1.2em;
    color: #000000;
}

.main-profile form input{
    border: 2px solid #6d96b3c9;
    box-sizing: border-box;
    border-radius: 4px;
}

.main-profile .modal-footer button{
    font-size: large;
    margin-top: 20px;
    width: 140px;
}

.main-profile .modal-footer{
    padding: 1rem;
}