.container-login .row .block-1{ /*fica*/
    padding: 7em 6em;
}

.container-login .row .block-1 .title{ /*fica*/
    font-size: 47px;
}

.container-login .row .block-1 div.welcome{ /*fica*/
    font-size: 40px;
    margin-bottom: 1.2em;
}

.container-login .row .block-2{
    padding: 2.5em 6em;
}

.container-login .row .block-2 input{
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: none;
    width: 100%;
}

.container-login .row .block-2 .input{
    margin: 0.9em 0;
    padding: 10px;
    color: black;
    width: 100%;
    font-size: 22px;
    border: 2px solid #0D7CCE;
    
}

.container-login .row .block-2 input:focus-visible{
    outline: 0;
}

.container-login .row .block-2 .input .p-viewer{
    margin-left: 7px;
}

.container-login .row .block-2 .input .p-viewer:hover{
    cursor: pointer;
}

.container-login .row .block-2 .title{
    font-size: 38px;
    text-align: center;
    /* margin: 25px 0; */
    color: #0D7CCE;
    font-weight: bold;
}

.container-login .row .block-2 .card{
    background-color: #eaeaea;
    max-width: 35em;
    margin: auto;
    margin-top: 5em;
    box-shadow: 0px 4px 3px #a7a7a7;
}

.container-login .row .block-2 .card-body{
    padding: 1em 2.2rem 3em;
}

.container-login .row .block-2 .card-body hr{
    margin: 0 1.3em 2.2em;
    color: #0D7CCE;
    height: 2px;
    opacity: 1;
}

.container-login .row .block-2 .card-body .forgotPassword{
    font-size: 23px;
    color: #0D7CCE;
    text-decoration: none;
}

.container-login .row .block-2 button.acessar{
    width: 100%;
    padding: 6px 10px;
    font-size: 22px;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 0;
    margin: 1.5em 0 1.5em;
    background: #0D7CCE;
    box-shadow: 0px 4px 3px #b5bcc1;
}

.container-login .row .block-2 button.acessar:hover{
    opacity: 0.8;
}


@media only screen and (max-width: 768px) {
    .container-login .row .block-2 .card{
        margin-top: 1em;
    }
}

.error-msg {
    font-size: 1.2rem;
    color: red;
    text-align: center;
}