.footer .block-3{
    background-color: #0D7CCE;
}

.footer .block-3{
    display: flex;
    align-items: center; 
}

.footer .block-3 .address{
    font-size: 18px;
    color: white;
    padding: 0 3em;
    width: 30em;
    margin-left: auto;
}

.footer footer{
    background-color: #c3bfbf;
    color: white;
    display: flex;
    align-items: center; 
    justify-content: center;
    font-size: 17px;
}