.alert{
    border-radius: 4px;
    margin: 0;
    width: 100%;
    padding: 8px !important;
    display: flex;
    align-items: center;
}

.alert-content{
    margin-right: 20px;
}

.alert .alert-icon{
    display: inline-block;
    font-size: 32px;
    margin-right: 10px;
}

.alert .alert-text{
    font-size: 20px;
}

.alert-close{
    position: absolute;
    right: 10px;
    border: 0;
    background: transparent;
    font-size: 20px;
      
    transition: 0.2s ;
}
  
.alert-close:hover{
    color: #FFFFFF;
}