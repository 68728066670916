:root {
  --backgroundModal: #F0F2F5;
  --red: #DB280F;
  --blue: #0D7CCE;
  --green: #239f36;
  --gray: #C4C4C4;
  --dark_gray: #747474;
  --yellow: #FFEE88;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,body, #root {
  height:100%;
  padding:0;
}



/* "Painel" de ações das páginas (inputs, selects e bottons) */
.navigation {
  text-decoration: none;
  color: #000000;
  font-size: 20px;
  padding-top: 20px;
  margin-top: 0;
}

.hide-area {
  display: none;
}

.area-alert {
  display: inline-block;
  padding-top: 20px;
}

.action-panel {
  padding-top: 1em;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding-right: 0 !important;
}

.action-panel .btn-area {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

.action-panel .btn {
  color: #ffffff;
  background: #0D7CCE;
  font-size: inherit;
  font-weight: bolder;
  transition: filter 0.2s;
  width: 100%;
}

.action-panel .btn:hover {
  opacity: 0.8;
  background: #0D7CCE;
}

.action-panel select{
  font-size: inherit;
  background-color: inherit;
  padding: 6px;
}

.action-panel .select-options {
  width: 100%;
  border-radius: 4px;
}

.action-panel .search-input {
  padding: 0.375rem 0.75rem;
  font-size: inherit;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.action-panel  .search-input:focus {
  border-color: #0060df;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset,
    0px 0px 8px rgba(0, 96, 223, 0.5);
}



/*Estilização das tabelas*/
.table{
  padding: 16px 0 !important;
  margin: 0;
  width: 100%;
}

.table table{    
  padding: 32px 0;
  margin: 0;
  width: 100%;
}

.table .titles{
  font-size: 20px;
  color: #FFFFFF;
  background: #0D7CCE;
  border: none;   
}

.table .rows{
  font-size: 16px;
  color: #000000;
  border-spacing: 2px;
}

.table thead th, .table td {
  vertical-align: middle;
}

.table .settings{
  text-align: right;
}

.table .settings .settings-icons{
  white-space: nowrap;
}

.table .settings .settings-icons span{
  margin-left: 20px;
  cursor: pointer;
}

.table .settings .delete{
  color: var(--red);
}



/*Estilização básica dos modais*/
.react-modal-overlay{
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.react-modal-content{
  width: 100%;
  max-width: 800px;
  background: var(--backgroundModal);
  position: relative;
  border-radius: 4px;
  max-height: 95%;
}

.react-modal-title{
  border-width: 0 0 1px 0;
  width: 100%;
  padding: 16px 16px 0 16px;
  border-style: solid;
  border-color: #C3BFBF;
  text-align: center;
  font-weight: normal;
}

.react-modal-close{
  position: absolute;
  right: 10px;
  top: 10px;
  border: 0;
  background: transparent;
  font-size: 20px;
  color: #000000;

  transition: 0.2s ;
}

.react-modal-close:hover{
  color:rgba(249, 40, 15, 0.8);
}

.react-modal-body{
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 70vh;
}

.react-modal-body .modal-input-group{
  margin: 10px 0;
}

.react-modal-body .modal-input-group label,
.react-modal-body .modal-input-group .label{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0;
}

.react-modal-body .modal-input-group h5{
  text-align: right;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0;
}

.react-modal-body .modal-input-group .label .file-input{
  display: grid;
}

.react-modal-body .modal-input-group .label span{
  cursor: pointer;
}

.react-modal-body .modal-input-group .modal-input{
  background-color: inherit;
  border-color: #0D7CCE;
  border-radius: 4px;
  border-width: 2px;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.react-modal-content .modal-input-group .btn{
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: bolder;

  margin: 0 10px 0 0;
  transition: filter 0.2s;

  display: inline-block;
  justify-content: left;
  width: auto;
}

.react-modal-body .modal-input-group img{
  max-width: 150px;
  border-radius: 14%;
}

.react-modal-body .modal-checkbox{
  display: inline-block;
  margin-right: 20px;
  margin-top: 10px;
}

.react-modal-body .modal-checkbox input[type="checkbox"]{
  margin: 1px 5px 0 0;
  width: 20px;
  height: 20px;
}

.react-modal-body .modal-text{
  display: block;
  margin-bottom: 20px;
  font-size: 18px;
  text-align: center;
}

.react-modal-body .modal-subtext{
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
}

.react-modal-foot{
  min-height: 10%;
  padding: 20px;
}

.react-modal-btn{
  display: flex;
  justify-content: right;
}

.react-modal-btn .btn{
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: bolder;

  margin: 0 0 0 10px;
  transition: filter 0.2s;
}

.react-modal-btn .btn:hover{
  filter: brightness(0.7);
  color: #FFFFFF;
}

.react-modal-content .btn-blocked{
  background: #C4C4C4;  
}

.react-modal-content .btn-red{
  background: var(--red) !important;
}

.react-modal-content .btn-blue{
  background: var(--blue) !important;
}

.react-modal-content .btn-green{
  background: var(--green) !important;
}

.react-modal-content .btn-yellow{
  background: var(--yellow) !important;
}

.react-modal-btn .btn-blocked:hover{
  filter: brightness(1);
}

.react-modal .modal-select{
  width: 100%;
  background-color: inherit;
}